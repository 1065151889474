<template>
  <div class="container">
    <el-tabs type="border-card" v-model="tabFirstName" @tab-click="handleFirstClick">
          <el-tab-pane v-for="(row, index)  in serverAccounts" :value="row.label" :name="index" :key="index">
            <span slot="label"> {{ index }}</span>
    <el-tabs type="border-card" style="margin-top:20px" v-model="tabName" @tab-click="handleClick">
      <el-tab-pane v-for="item in row" :key="item.key" :value="item.label" :name="item.label">
        <span slot="label"><i :id="'tab-' + item.label" class="el-icon-s-platform"></i> {{ item.ip +
            '（' + item.label + '）'
        }}</span>
        <el-tabs type="border-card" style="margin-top:20px">
          <el-tab-pane label="负载饼图概览">
            <a :href="item.baoTaPath" :hidden="item.baoTaPath.indexOf('http:') == -1 && item.baoTaPath.indexOf('https:') == -1 " target="_blank">
              <el-button type="primary" icon="el-icon-share"> 宝塔 </el-button>
            </a>
            <a :href="item.rabbitWebPath" :hidden="!item.rabbitWebPath" target="_blank" style="margin-left: 10px;">
              <el-button type="primary" icon="el-icon-share"> RabbmitMq平台  </el-button>
            </a>
            <el-card style="margin-top:20px">
              <div class="charts_stats">
                <div>
                  <div>cpu使用率</div>
                  <div :id="item.label + 'cpu'" class="charts_stats_pie"></div>
                </div>
                <div style="margin-left:150px">
                  <div>内存使用率</div>
                  <div :id="item.label + 'memory'" class="charts_stats_pie"></div>
                </div>
                <div style="margin-left:150px">
                  <div>硬盘使用率1</div>
                  <div :id="item.label + 'hardDisk1'" class="charts_stats_pie"></div>
                </div>
                <div style="margin-left:150px">
                  <div :id="item.label + 'div2'">硬盘使用率2</div>
                  <div :id="item.label + 'hardDisk2'" class="charts_stats_pie"></div>
                </div>
              </div>
            </el-card>
          </el-tab-pane>
          <el-tab-pane label="负载折线走势图">
            <el-card style="margin-top:20px">
              <div class="charts_stats">
                <div>
                  <div>
                    <div :id="item.label + 'cpu_load'" class="charts_stats_line"></div>
                  </div>
                </div>
                <div style="margin-left:150px">
                  <div>
                    <div :id="item.label + 'memory_load'" class="charts_stats_line"></div>
                  </div>
                </div>

              </div>
            </el-card>
          </el-tab-pane>

        </el-tabs>
        <el-card style="margin-top:20px">
            <div class="charts_stats">
              <div>
                <div :id="item.label + 'app1'" class="charts_stats_bar"></div>
              </div>
              <div style="margin-left:150px">
                <div :id="item.label + 'app2'" class="charts_stats_bar"></div>
                方式：
                <el-radio-group v-model="javaServerRadio">
                  <el-radio :label="1">
                    <el-tooltip class="item" effect="dark" content="查看微服务的运行日志" placement="top">
                      <el-button type="text" style="color:black">
                        <el-tag effect="dark" type='' size="mini">
                          log
                        </el-tag>
                      </el-button>
                    </el-tooltip>

                  </el-radio>
                  <el-radio :label="2">
                    <el-tooltip class="item" effect="dark" content="查看微服务cpu飚高问题线程的定位" placement="top">
                      <el-button type="text" style="color:black">
                        <el-tag effect="dark" type='success' size="mini">
                          jstack
                        </el-tag>
                      </el-button>
                    </el-tooltip>
                  </el-radio>
                  <!-- <el-radio :label="3">备选项</el-radio> -->
                </el-radio-group>
              </div>
            </div>
            </el-card>
      </el-tab-pane>

    </el-tabs>
          </el-tab-pane>
    </el-tabs>
    <el-card style="margin-top:20px">
      <div slot="header" class="clearfix">
        <span>基础信息</span>
      </div>
      <div class="ossClass">
        <div style="color:#FC7D02;">{{ ossTitle }}</div>

        <el-tooltip class="item" effect="dark" placement="right">
          <div id="water" style="width:250px;height:250px;"> </div>
          <div slot="content" v-html="ossContent"></div>
        </el-tooltip>
      </div>
    </el-card>
    <el-dialog :fullscreen="true" :title="dialogTitle" :visible.sync="dialogVisible" width="50%" center
      :before-close="clickModelHandler">
      <div class="logDiv" ref="logDiv">{{ javaPushLog }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeDialog">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script src="https://cdn.jsdelivr.net/npm/echarts-liquidfill@3/dist/echarts-liquidfill.min.js"></script>
<script>
  import * as echarts from 'echarts';
  import 'echarts-liquidfill';
  import requestApi from "@/api/server.js";
  import pushApi from "@/api/push.js";
  import requestUserApi from "@/api/user.js";
  let myBarChart;

  let timer;
  let timerLine;
  let lxTime = 5000;

 let myChart_cpu_load;
 let myChart_memory_load;


  export default {
    mounted() {
      this.timePoll(); 
     
    },
    created() {
      this.getAccounts();
      this.tabs();
      this.drawOssChart();
      
    },destroyed(){
      clearInterval(timer);
      clearInterval(timerLine);
    },
    data() {
      return {
        userId: localStorage.getItem("userId"),
        serverAccounts: [],
        ossTitle: "",
        ossContent: "",
        tabName:"test",
        tabFirstName:"MHP",
        dialogVisible: false,
        javaPushLog: "",
        dialogTitle:"日志",
        javaServerRadio:1
      }
    },
    methods: {
      handleFirstClick(tab){
        //主tab点击切换事件
        Object.keys(this.serverAccounts).forEach((a,a_index)=>{
          Object.values(this.serverAccounts).filter((b,b_index) => {
            if( tab.name==a && a_index==b_index){
            
              this.tabName=b[0].label
            }
          });
        })
        this.tabs();
      },
    handleClick(tab, event) {
        this.tabName=tab.name
        this.tabs();
      },
      timePoll() {
        timer = setInterval(() => {
           this.tabs();
        }, lxTime);
       
      },
      tabs() { 
      
        Object.values(this.serverAccounts).forEach(row => {
          row.forEach(f => {
           
            if(f.label==this.tabName){
              console.log(f.label)
              this.drawChart(f);
              this.drawLineChart(f)
            }
          });
      });

      },
      async getAccounts() {
        let params = {};
        await this.$get(requestApi.getAccounts, params).then((res) => {
          if (res.success && res.statusCode == 200) {
            this.serverAccounts = res.data; // 修正这里的名称
            const serverGroupedBy = {};

            for (const item of this.serverAccounts) {
                if (serverGroupedBy[item.group]) {
                    serverGroupedBy[item.group].push(item);
                } else {
                    serverGroupedBy[item.group] = [item];
                }
            }

            let getUserLinkServerParams = { userId: this.userId }
          //根据用户配置的权限，显示
          this.$get(requestUserApi.getUserLinkServer, getUserLinkServerParams).then(f=>{
            if (res.success && res.statusCode == 200) {
              let filteredA = {};
              f.data.forEach(key => {
                if (serverGroupedBy.hasOwnProperty(key)) {
                  filteredA[key] = serverGroupedBy[key];
                }
              });
              this.serverAccounts = filteredA; // 修正这里的名称
              console.log(this.serverAccounts);
              this.tabs();
            }
          })

           
          }
        });
      },
      drawChart(row) {
        let env = row.label;
        let params = { env: env };
        this.$get(requestApi.getServerInfo, params).then((res) => {
          if (res.success && res.statusCode == 200) {
            this.drawCpuChart(env,res);
            this.drawMemoryChart(env,res);
            this.drawHardDiskChart(env,res);
            this.drawAppChart(env,res)
          }
        });
       
      },
      drawLineChart(row) {
        let env = row.label;
        let params = { env: env };
        this.$get(requestApi.getCpuLineCharts, params).then((res) => {
          if (res.success && res.statusCode == 200) {
            //cpu 实时图
           
            myChart_cpu_load = echarts.getInstanceByDom(document.getElementById(env + "cpu_load"))
            if (myChart_cpu_load == null) { // 如果不存在，就进行初始化。
              myChart_cpu_load = echarts.init(document.getElementById(env + "cpu_load"));
            }
            let data = res.data;
            let load_option = this.getServerLoadLineOption(data, "Cpu折线走势图");
            myChart_cpu_load.setOption(load_option);
          }
        });

        this.$get(requestApi.getMemoryLineCharts, params).then((res) => {
          if (res.success && res.statusCode == 200) {
            //cpu 实时图
            myChart_memory_load = echarts.getInstanceByDom(document.getElementById(env + "memory_load"))
            if (myChart_memory_load == null) { // 如果不存在，就进行初始化。
              myChart_memory_load = echarts.init(document.getElementById(env + "memory_load"));
            }
            let data = res.data;
            let load_option = this.getServerLoadLineOption(data, "内存折线走势图");
            myChart_memory_load.setOption(load_option);
          }
        });
      }
      ,
      //cpu监控
      drawCpuChart(env,res) {
        //cpu 饼图
        let myChart = echarts.getInstanceByDom(document.getElementById(env + "cpu"))
        if (myChart == null) { // 如果不存在，就进行初始化。
          myChart = echarts.init(document.getElementById(env + "cpu"));
        }
        let data = res.data
        let cpuInfo = data.cpuInfo;
        let useTotal = parseFloat(cpuInfo.cpuUserUse) + parseFloat(cpuInfo.cupSysUse);
        useTotal = useTotal.toFixed(2);
        let option = this.getPieOption(1, useTotal, cpuInfo.cpuIdle);
        myChart.setOption(option);
        document.getElementById('tab-' + env).style.color = cpuInfo.serverLoadColorTab;


      },
      //内存监控
      drawMemoryChart(env,res) {
        let data = res.data;
        // 指定图表的配置项和数据
        let myChart = echarts.getInstanceByDom(document.getElementById(env + "memory"));
        if (myChart == null) { // 如果不存在，就进行初始化。
          myChart = echarts.init(document.getElementById(env + "memory"));
        }
        let memoryInfo = data.memoryInfo;
        let percentage = memoryInfo.percentage;
        let useIdle = parseInt(memoryInfo.memoryTotal) - parseInt(memoryInfo.memoryUse);
        let subTitle = memoryInfo.memoryUse + " / " + memoryInfo.memoryTotal + " (Mb)";
        let option = this.getPieOption(2, memoryInfo.memoryUse, useIdle, percentage, subTitle);
        myChart.setOption(option);
        window.addEventListener('resize', myChart.resize);
      },
      //硬盘监控
      drawHardDiskChart(env,res) {
        let data = res.data.hardDiskInfos;
        let index =1;
        data.forEach(element => {
            let myChart = echarts.getInstanceByDom(document.getElementById(env + "hardDisk"+index));
            if (myChart == null) { // 如果不存在，就进行初始化。
              myChart = echarts.init(document.getElementById(env + "hardDisk"+index));
            }
            // 指定图表的配置项和数据         
            let subTitle = element.used + " / " + element.totalSize + " (Gb)"
            let option = this.getPieOption(3, element.used, element.avail, element.usedRate, subTitle);
            myChart.setOption(option);
            index++;
        });
       
        if(data.length==1){
            document.getElementById(env + "div2").style.display = 'none';
        }
      
       
      },
      getPieOption(type, usedValue, availValue, percentage, subTitle) {
        let title = "";
        let subtext = ""
        if (type == 1) {
          //cpu
          title = usedValue + "%";

        } else if (type == 2) {
          //内存
          title = percentage + "%";
          subtext = subTitle;

        } else if (type == 3) {
          //硬盘
          title = percentage + "%";
          subtext = subTitle;
        }
        let color = "#67C23A";
        if ((percentage >= 50 && percentage < 70)) {
          color = "#E6A23C";
        } else if (percentage >= 70 && percentage < 90) {
          color = "#F56C6C";
        } else if (percentage >= 90) {
          color = "red";
        }
        let option = {
          title: {
            text: title,
            left: 'center',
            top: 'center',
            subtext: subtext
          },
          series: [
            {
              type: 'pie',
              label: {
                show: true,
                position: 'left'
              },
              itemStyle: {
                normal: {
                  color: function (colors) {
                    var colorList = [
                      color,
                      '#DCDFE6'
                    ];
                    return colorList[colors.dataIndex];
                  }
                }
              },
              data: [
                {
                  value: usedValue,
                  name: ''
                },
                {
                  value: availValue,
                  name: ''
                }
              ],
              radius: ['70%', '85%']
            }
          ]
        };
        return option;
      },
      //java 应用程序监控
      drawAppChart(env,res) {
        let _this=this;
        let data = res.data.appChats;
        let index = 1;
        data.forEach(f => {
           myBarChart = echarts.getInstanceByDom(document.getElementById(env + "app" + index));
          if (myBarChart == null) { // 如果不存在，就进行初始化。
            myBarChart = echarts.init(document.getElementById(env + "app" + index));
          }
          let option = this.getBarOption(index, f.arrays, f.data1, f.data2);
          myBarChart.setOption(option);
         if(index>1){
          window.addEventListener('resize', myBarChart.resize);
          myBarChart.on('click', function (params) {   
              _this.view(env,params.name)             
		    	});
         }
         
          index++;
         
        });
      },
      getBarOption(index, arrays, data1, data2) {
        let text = "Java第三方服务";
        if (index == 2) {
          text = "Java应用程序";
        }
        let option = {
          title: {
            text: text
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {},

          xAxis: {
            type: 'category',
            data: arrays
          },
          yAxis: {

          },
          series: [
            {
              type: 'bar',
              data: data1,
              name: 'cpu',
              label: {
                legendHoverLink: true,				//---图形上的文本标签				
                show: true,
                position: 'insideTop',	//---相对位置
                rotate: 0,				//---旋转角度
                color: '#eee',
              },
              itemStyle: {
                normal: {
                  label: {
                    show: true,//设置显示文字
                    position: 'top'
                  }
                }
              }
            },
            {
              type: 'bar',
              name: '内存',
              data: data2,
              label: {
                legendHoverLink: true,				//---图形上的文本标签
                show: true,
                position: 'insideTop',	//---相对位置
                rotate: 0,				//---旋转角度
                color: '#eee',
              },
              itemStyle: {
                normal: {
                  label: {
                    show: true,//设置显示文字
                    position: 'top'
                  }
                }
              }
            }
          ]

        };
        return option;
      },
      //oss 阿里云存贮监控
      drawOssChart() {
        let params = {};
        // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
        this.$get(requestApi.getOssInfo, params).then((res) => {
          if (res.success && res.statusCode == 200) {

            let data = res.data;
            let myChart = echarts.getInstanceByDom(document.getElementById("water"));
            if (myChart == null) { // 如果不存在，就进行初始化。
              myChart = echarts.init(document.getElementById("water"));
            }
            let title = "阿里云Oss：" + data.used + " GB / " + data.total + " GB";
            this.ossTitle = title;
           
            let content = "";
            data.bucketList.forEach(f => {
              content += f.key + "  使用：" + f.value + " GB <br/><br/>";
            })
            content = content + "合计使用：" + data.used + " GB";
            this.ossContent = content;
            let option = this.getWaterBallOption(data.rate);
            myChart.setOption(option);
            window.addEventListener('resize', myChart.resize);
          }
        });
      },
      getWaterBallOption(value) {
        let option = {
          series: [{
            type: 'liquidFill',
            data: [value]
          }]
        };
        return option;
      },
      //负载条纹图
      getServerLoadLineOption(data, title) {
        let option = {
          title: {
            text: title,
            left: '1%'
          },
          tooltip: {
            trigger: 'axis'
          },
          grid: {
            left: '5%',
            right: '15%',
            bottom: '10%'
          },
          xAxis: {
            data: data.keys,
          
          },
          yAxis: {},
          toolbox: {
            right: 10,
            feature: {
              dataZoom: {
                yAxisIndex: 'none'
              },
              restore: {},
              saveAsImage: {}
            }
          },
          dataZoom: [
            {
              startValue: data.startValue
            }
            
          ]
          ,
          visualMap: {
            top: 50,
            right: 10,
            pieces: [
              {
                gt: 0,
                lte: 30,
                color: '#93CE07'
              },
              {
                gt: 30,
                lte: 40,
                color: '#FBDB0F'
              },
              {
                gt: 40,
                lte: 50,
                color: '#FC7D02'
              },
              {
                gt: 50,
                lte: 70,
                color: '#FD0100'
              },
              {
                gt: 70,
                lte: 80,
                color: '#AA069F'
              },
              {
                gt: 80,
                color: '#AC3B2A'
              }
            ],
            outOfRange: {
              color: '#999'
            }
          },
          series: {
            name: title,
            type: 'line',
            data: data.values
          
          }
        };
        return option;
    },
    jarLog(envCode,serverPort) {
      let params = {  envCode: envCode, serverPort: serverPort,type:this.javaServerRadio };
      this.$get(pushApi.getBarJarLog, params).then((res) => {
        if (res.success && res.statusCode == 200) {
          this.javaPushLog = res.data;
          this.scrollToBottom();

        }
      });

    },
    closeDialog() {
      this.clickModelHandler();
     
    },
    clickModelHandler(){
      this.dialogVisible = false;
      this.javaPushLog="";
    
    },
    scrollToBottom() { 
      this.$nextTick(() => {
        let container = document.getElementsByClassName("logDiv");
		    container.scrollTop = container.scrollHeight;
        }); 
    },
    view(envCode,serverPort) {
      this.dialogVisible = true;
      this.dialogTitle=envCode+"（"+serverPort+"）";
      this.jarLog(envCode,serverPort);
      
      
    }
    }
  }
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
}

.charts_stats {
  width: 100%;
  display: flex;
  justify-content: left;
  text-align: center;

}


.charts_stats_pie {
  width: 13vw;
  height: 250px;
}

.charts_stats_line {
  width: 35vw;
  height: 250px;
}

.charts_stats_bar {
  width: 550px;
  height: 350px;
}

.ossClass {
  width: 300px;
  text-align: center;
}

.logDiv {
  white-space: pre-wrap;
  background-color: whitesmoke;
  color: black;
  padding: 10px;
}
</style>